import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { isoDateToMmYyyy } from '../../../utils/date';

enum AssociationFormAction {
  CREATE = 'CREATE',
  PATCH = 'PATCH',
}

interface VehicleData {
  engine: string | null;
  id: number;
  line: string | null;
  lineimg: string | null;
  manufacturer: string | null;
  manufactuterimg: string | null;
  model: string | null;
  thumb: string | null;
  vehicle_line_id: number | null;
  vehicle_manufacturer_id: number | null;
  version: string | null;
  year_from: string | null;
  year_to: string | null;
}

@Component({
  selector: 'app-part-vehicle',
  templateUrl: './part-vehicle.component.html',
  styleUrls: ['./part-vehicle.component.css']
})
export class PartVehicleComponent implements OnInit {
	baseUrl:string;
  baseImg:string;
	allPartsResponse:any;
	allParts:any;
	allVehicles:any;
	allVehiclesRes:any;
	partQuery:string
	vehicleQuery:string;
	selectedPart:any;
	closeResult:string;
	selectedVehicleToShow:string;
  imageToshow:string;
  allAssociated:any;
  associatedRes:any;
  selectedVehicle: any;
  details: string | null;
  formModal: any;
  selectedAssociation: any;
  associationFormActionEnum: typeof AssociationFormAction = AssociationFormAction;
  associationFormAction: AssociationFormAction = AssociationFormAction.CREATE;

  @ViewChild('content') content: ElementRef;
  @ViewChild('associationForm') associationForm: ElementRef;

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.baseUrl = environment.baseUrl;
    this.baseImg = environment.baseImgPrefix;
  }

  get formTitle(): string {
    return this.associationFormAction === this.associationFormActionEnum.CREATE
      ? 'Descreva as particularidades desta associação'
      : 'Editar observação'
  }

  get submitAction() {
    return this.associationFormAction === this.associationFormActionEnum.CREATE
      ? this.associateVehicle()
      : this.updateAssociationDetail()
  }

  get submitButtonText(): string {
    return this.associationFormAction === this.associationFormActionEnum.CREATE
      ? 'Salvar'
      : 'Atualizar'
  }

  ngOnInit() {
  	this.retrieveAllParts('');
  	this.retrieveAllVehicles('');
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = this.baseImg+thumb;
  	this.selectedVehicleToShow = name;
  	this.open(content)
  }

  openAssociationForm(selectedVehicle: any, action: AssociationFormAction) {
    this.associationFormAction = action;

    if(!this.selectedPart) {
      return this.toastr.error('Você precisa escolher um filtro antes!', 'ERRO!', {positionClass: 'toast-top-center'});
    }

    if (selectedVehicle.details) {
      this.details = selectedVehicle.details;
    }

    if (action === AssociationFormAction.CREATE) {
      this.selectedVehicle = selectedVehicle;
    } else {
      this.selectedAssociation = selectedVehicle;
    }

    this.formModal = this.modalService.open(this.associationForm)

    this.formModal.result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, (_: any) => {
      this.closeModal()
    })
  }

  closeModal() {
    this.formModal.close()
    this.selectedVehicle = {}
    this.selectedAssociation = {}
    this.details = ''
  }

  retrieveAllParts(query) {
  	console.log(query)
  	this.http.get(this.baseUrl+'/parts/partsearch?query='+query)
    .subscribe(
      res => {
        console.log('parts', res)
        this.allPartsResponse = res;
        this.allParts = this.allPartsResponse.data;
      },
      err => {
      	console.log(err)
      })
  }

  retrieveAllVehicles(vehiQuery) {
  	console.log(vehiQuery)
  	this.http.get(this.baseUrl+'/vehicles/vehiclesearch?query='+vehiQuery)
    .subscribe(
      (res: { data: VehicleData[] }) => {
        console.log(res)
        this.allVehiclesRes = this.formatVehiclesProperties(res);
        this.allVehicles = this.allVehiclesRes;
      },
      err => {
      	console.log(err)
      })
  }

  formatVehiclesProperties(vehicles:  { data: VehicleData[] }): any {
    vehicles.data.forEach(vehicle => (
      Object.assign(vehicle, {
        year_from: vehicle.year_from ? isoDateToMmYyyy(vehicle.year_from) : '<--',
        year_to: vehicle.year_to ? isoDateToMmYyyy(vehicle.year_to) : '-->'
      })
    ))

    return vehicles;
  }

  filterVehicleByVersion(versionQuery) {
    this.allVehicles = Object.assign({}, this.allVehiclesRes);

    if (versionQuery != null || versionQuery == '') {
      this.allVehicles.data = this.allVehicles.data.filter(x => {
        if (x.version)
          return x.version.toLowerCase().includes(versionQuery.toLowerCase());
      });
    }
  }

  associateVehicle() {
    if(!this.selectedPart) {
      return this.toastr.error('Você precisa escolher um filtro antes!', 'ERRO!', {positionClass: 'toast-top-center'});
    }
    this.http.post(this.baseUrl+'/part_vehicles/associate', {
      data: {
        part_id: this.selectedPart.id,
        details: this.details ? this.details : null,
        vehicle_id: this.selectedVehicle.id
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.sendPartToIndex(this.selectedPart.code)
        this.retrievePartVehicles(this.selectedPart)
        this.sendVehiToIndex(this.selectedVehicle.id)
        this.closeModal()
        this.toastr.success('Veiculo Associado corretamente', 'Successo', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Erro ao associar!', 'ERRO', {positionClass: 'toast-top-center'});
      })
  }

  retrievePartVehicles(part) {
    console.log(part)
    this.http.get(this.baseUrl+'/parts/vehiclesearch?partId='+part.id)
    .subscribe(
      (res: { data: VehicleData[] }) => {
        console.log('allAssociated', res)
        this.associatedRes = this.formatVehiclesProperties(res);
        this.allAssociated = this.associatedRes.data;
      },
      err => {
        console.log(err)
      })
  }

  removeAssociation(association) {
    // console.log(id, this.selectedPart)
    this.http.delete(this.baseUrl+'/part_vehicles/'+association.associationId)
    .subscribe(
      res => {
        console.log(res)
        this.sendPartToIndex(this.selectedPart.code);
        this.sendVehiToIndex(association.vehicleId)
        this.retrievePartVehicles(this.selectedPart)
        this.toastr.success('Veiculo removido com sucesso', 'Successo', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Erro ao remover veículo!', 'ERRO', {positionClass: 'toast-top-center'});
      })
  }

  updateAssociationDetail() {
    this.http.patch(`${this.baseUrl}/part_vehicles/${this.selectedAssociation.associationId}`, { details: this.details ? this.details : null })
    .subscribe(
      res => {
        console.log(res)
        this.retrievePartVehicles(this.selectedPart)
        this.closeModal()
        this.toastr.success('Observação atualizada', 'Successo', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Erro ao atualizar observação!', 'ERRO', {positionClass: 'toast-top-center'});
      })
  }

  sendPartToIndex(partCode) {
    console.log('mandou', this.selectedPart.code)
    this.http.get(this.baseUrl+'/parts/sendtoindex?partcode='+partCode)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Peça na fila para indexar!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Falha ao enviar peça para indexação, salve novamente!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

  sendVehiToIndex(vehiid) {
    console.log('mandou', this.selectedPart.code)
    this.http.get(this.baseUrl+'/vehicles/sendtoindex?vehiid='+vehiid)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Veículo na fila para indexar!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Falha ao enviar peça para indexação, salve novamente!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

}
