import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banner-create',
  templateUrl: './banner-create.component.html',
  styleUrls: ['./banner-create.component.css']
})
export class BannerCreateComponent implements OnInit {
	newBanner: any = {};
  environment: any;
  imgUrl: string;
  baseUrl: string;
  folder: string;
  file: any;
  errorMsg: string;
  errorTitle:string;
  errorBody:string;
  closeResult:any;

	acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal, public toastr: ToastrService) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
    this.folder = 'imgsbanner';
  }

  ngOnInit() {

  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 500000;
  }

  previewFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.open(this.contentError)
    }
  }

  uploadFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUri) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      // TODO: send to server
      this.http.post(this.imgUrl, {
        folder: this.folder,
        image: base64File
      }).subscribe((res: any) => {
        this.createBanner(res.fileName);
      }, err => console.error(err));
    }
  }

  createBanner(fileName: string) {
    this.errorTitle = 'CRIANDO BANNER';
    this.errorBody = 'Estamos subindo a imagem e as informações do novo Banner!';
    this.open(this.contentError)

    this.http.post(this.baseUrl+'/banners', {
      title: this.newBanner.title,
      thumb: '/' + fileName,
      link: this.newBanner.link,
      time: 5000,
      status: 1
    }).subscribe(
      res => {
        console.log('respost', res)
        this.errorTitle = 'BANNER CRIADO';
        this.errorBody = 'Banner criado com sucesso!!';
        this.open(this.contentError)
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err)
      }
    )
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

}
