/**
 *
 * @param date
 * @returns data no formato string (AAAA/MM/DD)
 */
export function mmYyyyToDate(date: string): string {
  if (date.match(/[0-9]{2}\/[0-9]{4}/)) {
    const [month, year] = date.split('/').map(datePart => Number(datePart));

    if (!month || !year) {
      return null;
    }

    return new Date(year, month - 1).toISOString().split('T')[0];
  }

  return null;
}

/**
 *
 * @param date
 * @returns data no formato string (MM/AAAA)
 */
export function isoDateToMmYyyy(date: string | Date): string {
  if (date) {
    const year = String(new Date(date).getUTCFullYear());
    const month = String(new Date(date).getUTCMonth() + 1);

    return `${(month).padStart(2, '0')}/${year}`;
  }

  return null;
}

export function isAfterOrEqual(refDate: Date | string, refDateToCompare: Date | string): boolean {
  const date = new Date(refDate);
  const dateToCompare = new Date(refDateToCompare);

  return date.getTime() >= dateToCompare.getTime()
}
