import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dateIsNotZeroValidator, dateLengthValidator, isAfterValidator } from '../validators';
import { mmYyyyToDate } from '../../../utils/date';
@Component({
  selector: 'app-vehicle-create',
  templateUrl: './vehicle-create.component.html',
  styleUrls: ['./vehicle-create.component.css']
})
export class VehicleCreateComponent implements OnInit {
  environment: any;
  imgUrl: string;
  baseUrl: string;
  folder: string;
  file: any;
  showRedirect:boolean;
  errorMsg: string;
  errorTitle:string;
  errorBody:string;
  closeResult:any;
  vehicleName:string;
  allLines:any;
  allManufacturers:any;
  imageChangedEvent: any = '';
	croppedImage: any = '';
  form: FormGroup;

	acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal, public toastr: ToastrService, private fb: FormBuilder) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
    this.showRedirect = false;
    this.folder = 'imgsveiculos';
  }

  ngOnInit() {
  	this.retrieveAllLines();
  	this.retrieveManufacturers();
    this.initForm();
  }

  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }
  // imageCropped(image: string) {
  //     this.croppedImage = image;
  // }
  // imageLoaded() {
  //     // show cropper
  // }
  // loadImageFailed() {
  //     // show message
  // }

  initForm() {
    const formControls = {};
    const props = ['model', 'engine', 'version', 'line', 'manufacturer', 'yearFrom', 'yearTo'];
    props.forEach(prop => {
      formControls[prop] = [''];

      if (prop === 'yearFrom' || prop === 'yearTo') {
        formControls[prop].push([dateLengthValidator, dateIsNotZeroValidator]);
      }
    });
    this.form = this.fb.group(formControls, { validator: isAfterValidator });
  }

  retrieveAllLines() {
  	this.http.get(this.baseUrl+'/vehicle_lines', {})
  	.subscribe(
        res => {
          console.log('respost', res)
          this.allLines = res;
        }
      )
  }
  retrieveManufacturers() {
  	this.http.get(this.baseUrl+'/vehicle_manufacturers', {})
  	.subscribe(
        res => {
          console.log('respost', res)
          this.allManufacturers = res;
        }
      )
  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 5000000;
  }

  previewFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 5Mb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 5Mb';
      this.open(this.contentError)
    }
  }

  uploadFile(event: Event) {
    if (this.form.valid) {
      if(!this.fileDataUri) {
        this.toastr.warning('Nenhuma imagem atribuida, mas estamos iniciando criação de veículo!', 'ATENÇÃO!', {positionClass: 'toast-top-center'});
        this.vehicleName = 'NULL';
        this.createVehicle()
        return
      }

      this.toastr.warning('Iniciando criação de veículo!', 'AVISO!', {positionClass: 'toast-top-center'});
      this.errorMsg = '';

      event.preventDefault();
      // get only the base64 file
      if (this.fileDataUri.length > 0) {
        const base64File = this.fileDataUri.split(',')[1];
        this.vehicleName = this.form.value.model+this.form.value.engine+this.form.value.version;
        // TODO: send to server
        this.http.post(this.imgUrl+'/upload', {
          name: this.vehicleName,
          folder: this.folder,
          extention: 'png',
          image: base64File
        }).subscribe(
          res => {
            console.log('respost', res)
            this.createVehicle()
            this.toastr.success('Upload de Imagem concluido!', 'Successo!', {positionClass: 'toast-top-center'});
          },
          err => {
            console.log('errou', err);
            this.toastr.error('Erro ao fazer upload de imagem!', 'ERRO!', {positionClass: 'toast-top-center'});
          }
        )
      }
    } else {
      this.toastr.error('Preencha corretamente os campos inválidos!', 'ERRO!', {positionClass: 'toast-top-center'});
    }
  }

  createVehicle() {
    this.http.post(this.baseUrl+'/vehicles', {
      model: this.form.value.model,
      engine: this.form.value.engine,
      vehicle_line_id: this.form.value.line.id,
      thumb: '/' + this.folder + '/' + this.vehicleName + '.png',
      img: '/' + this.folder + '/' + this.vehicleName + '.png',
      vehicle_manufacturer_id: this.form.value.manufacturer.id,
      year_from: this.form.value.yearFrom ? mmYyyyToDate(this.form.value.yearFrom) : null,
      year_to: this.form.value.yearTo ? mmYyyyToDate(this.form.value.yearTo) : null,
      version: this.form.value.version,
      line_detail: this.form.value.line.name,
      addtoindex: 1
    }).subscribe(
      res => {
        console.log('respost', res)
        this.toastr.success('Veiculo Criado!', 'Successo!', {positionClass: 'toast-top-center'});
        // this.errorTitle = 'VEICULO CRIADO';
        // this.errorBody = 'Veículo criado com sucesso!!';
        // this.open(this.contentError)
        this.showRedirect = true;
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err);
        this.toastr.error('Erro ao criar veiculo!', 'ERRO!', {positionClass: 'toast-top-center'});
      }
    )
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

}
