import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css']
})
export class VideoListComponent implements OnInit {
	allVideos:any;
	environment: any;
  baseUrl: string;

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  	this.retrieveAllVideos()
  }

  retrieveAllVideos() {
  	this.http.get(this.baseUrl+'/videos')
    .subscribe(
      res => {
        console.log(res)
        this.allVideos = res;
      }, 
      err => {
      	console.log(err)
      })
  }

  removeVideo(id) {
    this.http.delete(this.baseUrl+'/videos/'+id)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Video removido com sucesso!', 'Success!', {positionClass: 'toast-top-center'});
        this.retrieveAllVideos();
      }, 
      err => {
        console.log(err)
        this.toastr.error('Não foi possível remover o vídeo!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

}