import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {
	allBanners: any;
	environment: any;
  baseUrl: string;
  baseImg: string;
  loadingBanners: boolean;
  imageToshow: string;
  selectedBanner: string;
  selectedBannerObject: any;
  closeResult:any;
  modalReference:any;

  @ViewChild('content') content: ElementRef;
  @ViewChild('contentVerify') contentVerify: ElementRef;

  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
  	this.baseUrl = environment.baseUrl;
    this.baseImg = environment.baseImgPrefix;
  }

  ngOnInit() {
  	this.retrieveAllBanners()
  }

  open(content) { 
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = this.baseImg+thumb;
  	this.selectedBanner = name;
  	this.open(content)
  }

  retrieveAllBanners() {
  	this.loadingBanners = true;
  	this.http.get(this.baseUrl+'/banners')
    .subscribe(
      res => {
        console.log(res)
        this.allBanners = res;
        this.loadingBanners = false;
      }, 
      err => {
      	console.log(err)
      })
  }

  changeStatus(banner) {
    this.http.post(this.baseUrl+'/banners/newstatus', {
      banner: {
        status: banner.status,
        id: banner.id
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.retrieveAllBanners()
        this.toastr.success('Status Alterado com Sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      }, 
      err => {
        console.log(err)
      })
  }

  removeBanner(banner) {
    this.selectedBannerObject = banner;
    this.open(this.contentVerify)
  }

  keepAction() {
    this.http.delete(this.baseUrl+'/banners/'+this.selectedBannerObject.id)
    .subscribe(
      res => {
        this.toastr.success('Banner removido com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        this.retrieveAllBanners();
        this.modalReference.close();
      }, 
      err => {
        console.log(err)
      }
    )
  }

}