import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tradefair-create',
  templateUrl: './tradefair-create.component.html',
  styleUrls: ['./tradefair-create.component.css']
})
export class TradefairCreateComponent implements OnInit {
	newFair:any = {};
	newCreatedFair:any;
	newFairId:number;
	newImages:any = [];
	newFiles:any;
	errorMsg:string;
	errorTitle:string;
	errorBody:string;
	imgUrl:string;
  baseUrl:string;
  folder:string;
  randomString:string;

	acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('multipleFileInput') multipleFileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
    this.folder = 'imgsfeiras';
  }

  ngOnInit() {
  	this.newFair.feature = 1;
  	this.newFair.status = 1;
  }

	validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 500000;
  }

  previewFileImages() {
    this.newImages = [];
    this.newFiles = this.multipleFileInput.nativeElement.files;
    console.log('files',this.newFiles);
	  for (var i = 0; i < this.newFiles.length; i++) {
	    if (this.newFiles[i] && this.validateFile(this.newFiles[i])) {
	    	this.errorMsg = '';
	      const reader = new FileReader();
	      reader.readAsDataURL(this.newFiles[i]);
	      reader.onload = () => {
	        this.fileDataUri = reader.result;
	        this.newImages.push(this.fileDataUri)
	      }
	    } else {
	      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
	      this.errorTitle = 'ARQUIVO INVÁLIDO';
	      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
	    }
	  }
  }

  createFair(event) {
  	console.log('teste')
  	this.http.post(this.baseUrl+'/trade_fairs', {
      title: this.newFair.title,
      description: this.newFair.description,
      status: this.newFair.status,
      feature: this.newFair.feature,
      position: 1
    }).subscribe(
      res => {
        console.log('respost', res)
        this.newCreatedFair = res;
        this.newFairId = this.newCreatedFair.id;
        this.toastr.success('Evento criado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err)
      }
    )
  }

  regenerateRandomString() {
  	this.randomString = Math.random().toString(36).substring(2, 15)
  }

  uploadFairImages(event: Event) {
    this.errorMsg = '';
    if(!this.newImages) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.newImages.length > 0) {
	    for (var i = 0; i < this.newImages.length; i++) {
	      this.regenerateRandomString();
	      const base64File = this.newImages[i].split(',')[1];
	      console.log(base64File)
	      // TODO: send to server
	      this.http.post(this.imgUrl, {
	        folder: this.folder,
          image: base64File
        }).subscribe((res: any) => {
          this.insertImageInTable(this.newFairId, res.fileName);
        }, err => console.error(err));
	    }
    }
  }

  insertImageInTable(trade_fair_id, thumb) {
  	this.http.post(this.baseUrl+'/trade_fair_images', {
  		trade_fair_id: trade_fair_id,
  		thumb: '/' + thumb,
  		img: '/' + thumb,
  		feature: 1
  	}).subscribe(
  		res => {
  			console.log(res)
        this.toastr.success('Imagem Atualizada com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
  		}, err => {
  			console.log(err)
  		})
  }

}
