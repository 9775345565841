import { Component, Input, OnInit } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ROUTES } from '../sidebar/menu-items';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class BreadcrumbComponent implements OnInit {
    routes:any;
    userLocation: any;
	// @Input() layout;
    pageInfo;
    constructor(private location: Location, private titleService: Title) {
        this.userLocation = location.path();
    }
    ngOnInit() {
        this.routes = ROUTES;
        for (var i = 0; i < this.routes.length; i++) {
            if(this.routes[i].path == this.userLocation) {
                this.pageInfo = this.routes[i];
                return this.titleService.setTitle(this.routes[i].page_title)
            }
            if(this.routes[i].submenu.length > 0) {
                for( var j = 0; j < this.routes[i].submenu.length; j++) {
                    if(this.routes[i].submenu[j].path == this.userLocation) {
                        this.pageInfo = this.routes[i].submenu[j];
                        return this.titleService.setTitle(this.routes[i].submenu[j].page_title)
                    }
                }
            }
        }
    }
}