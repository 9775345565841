import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { isAfterOrEqual } from "../../../utils/date";
interface ValidatorFn {
  (control: AbstractControl): ValidationErrors | null
}

export const isAfterValidator: ValidatorFn = (formGroup: FormGroup) => {
  let yearFrom = formGroup.get('yearFrom').value;
  let yearTo = formGroup.get('yearTo').value;
  const isEmptyOrNotFullyFilled = !yearFrom.length || (!yearTo.length || yearTo.length < 7);

  if (yearTo.length === 7) {
    [yearTo, yearFrom] = [yearTo, yearFrom].map(date => {
      return new Date(date.split('/').reverse().join('/'));
    })
  }

  /**
   * permite que campos vazios passem e que a validação ocorra somente após a data
   * ser completamente digitada
   */
  return (isEmptyOrNotFullyFilled || isAfterOrEqual(yearTo, yearFrom)
    ? null
    : { isAfter: '"Ano até" precisa estar após "Ano de"' }
  )
};

export const dateLengthValidator: ValidatorFn = (control) => {
  const dateLength = control.value.length;

  return dateLength === 7 || dateLength === 0 ? null : { dateLengthError: 'Ano deve estar vazio ou corretamente preenchido!' };
};

export const dateIsNotZeroValidator: ValidatorFn = (control) => {
  const dateLength = control.value.length;
  const date = control.value;

  if (dateLength === 7) {
    if (date.match(/[0-9]{2}\/[0-9]{4}/)) {
      const [month, year] = date.split('/').map((datePart: string) => Number(datePart));

      if (month === 0|| year === 0) {
        return { dateZeroError: 'Ano/mês não pode ser igual a 0!' };
      }

      return null;
    }
  }

  return null;
};
