import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '*', title: 'Consulta', page_title: 'Japanparts | Modulo Administrativo', icon: '', class: 'nav-small-cap', label: '', labelClass: '', extralink: true, submenu: []
    },
    {
        path: '', title: 'USUÁRIOS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-account-multiple', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-usuarios', title: 'LISTAR / CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            // { path: '/criar', title: 'CRIAR', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'PRODUTOS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-barcode-scan', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-produtos', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-produto', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/associar-produto-veiculo', title: 'VEICULO', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-car-connected', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/associar-produto-equivalencia', title: 'EQUIVALÊNCIA', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-repeat', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'CATEGORIAS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-tag', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-categorias', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-categoria', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '', title: 'SUB CATEGORIA', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-tag-plus', class: 'has-arrow', label: '', labelClass: '', extralink: false, submenu: [
                { path: '/listar-sub-categorias', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
                { path: '/criar-sub-categoria', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] }
            ] },
        ]
    },
    {
        path: '', title: 'VEÍCULOS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-car', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-veiculos', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-veiculo', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/fabricantes-veiculos', title: 'FABRICANTES', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-castle', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'BANNERS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-image-multiple', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-banners', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-banner', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'EVENTOS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-camera-party-mode', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-eventos', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-evento', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'VÍDEOS', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-filmstrip', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-videos', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
            { path: '/criar-video', title: 'CRIAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-plus', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'NEWSLETTER', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-email', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-newsletters', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'CONTATO', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-phone', class: 'has-arrow', label: '', labelClass: '', extralink: false,
        submenu: [
            { path: '/listar-contatos', title: 'LISTAR', page_title: 'Japanparts | Modulo Administrativo', icon: 'mdi mdi-format-list-bulleted', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
        ]
    },
    {
        path: '/login', title: 'LOGOUT', page_title: 'Japanparts | Modulo Administrativo', icon: 'fa fa-sign-out', class: '', label: '', labelClass: '', extralink: false, submenu: []
    },
];