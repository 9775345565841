import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
	allCategories: any;
	environment: any;
  baseUrl: string;
  loadingCategories: boolean;
  imageToshow: string;
  selectedCategory: string;
  closeResult:any;

  @ViewChild('contentError') contentError: ElementRef;

  constructor(private http: HttpClient, private modalService: NgbModal) {
  	this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  	this.retrieveAllCategories()
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = 'https://static.japanparts.com.br/'+thumb;
  	this.selectedCategory = name;
  	this.open(content)
  }

  retrieveAllCategories() {
  	this.loadingCategories = true;
  	this.http.get(this.baseUrl+'/part_categories')
    .subscribe(
      res => {
        console.log(res)
        this.allCategories = res;
        this.loadingCategories = false;
      },
      err => {
      	console.log(err)
      })
  }
}
