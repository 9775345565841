import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vehicle-manufacturers',
  templateUrl: './vehicle-manufacturers.component.html',
  styleUrls: ['./vehicle-manufacturers.component.css']
})
export class VehicleManufacturersComponent implements OnInit {
	allManufacturers: any;
	newManufacturer: any = {};
  environment: any;
  imgUrl: string;
  imageToshow:string;
  baseUrl: string;
  folder: string;
  file: any;
  showRedirect:boolean;
  loadingManufacturers:boolean;
  errorMsg: string;
  errorTitle:string;
  errorBody:string;
  closeResult:any;
  createNew:boolean;
  selectedManufacturer:string;

	acceptedMimeTypes = [
  	'image/gif',
  	'image/jpeg',
  	'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
    this.showRedirect = false;
    this.folder = 'imgsfabricanteveiculo';
  }

  ngOnInit() {
  	this.createNew = false;
  	this.retrieveAllManufacturers();
  }

  retrieveAllManufacturers() {
  	this.http.get(this.baseUrl+'/vehicle_manufacturers')
    .subscribe(
      res => {
        console.log('manu', res)
        this.allManufacturers = res;
      },
      err => {
        console.log(err)
      })
  }

  showCreateNewManufacturer() {
  	this.createNew = !this.createNew;
  	this.newManufacturer = {};
  }

	validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 500000;
  }

  previewFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.open(this.contentError)
    }
  }

  checkIfManufacturerExists(event: Event) {
    this.errorTitle = 'CRIANDO CATEGORIA';
    this.errorBody = 'Estamos subindo a imagem e as informações da nova categoria!';
    this.open(this.contentError)

    this.http.get(this.baseUrl+'/vehicle_manufacturers/findOne'+'?filter[where][name]='+this.newManufacturer.name)
    .subscribe(
      res => {
        this.errorTitle = 'FABRICANTE DUPLICADO';
        this.errorBody = 'Já existe uma fabricante com esse nome e o nome dos fabricantes devem ser únicos!';
        this.open(this.contentError)
      },
      err => {
        console.log(err)
        this.uploadFile(event)
      })
  }

  uploadFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUri) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      // TODO: send to server
      this.http.post(this.imgUrl, {
        folder: this.folder,
        image: base64File
      }).subscribe((res: any) => {
        this.createManufacturer(res.fileName);
      }, err => console.error(err));
    }
  }

  createManufacturer(fileName: string) {

    this.http.post(this.baseUrl+'/vehicle_manufacturers', {
      name: this.newManufacturer.name,
      description: this.newManufacturer.description,
      thumb: '/' + fileName,
      img: '/' + fileName,
    }).subscribe(
      res => {
        console.log('respost', res)
        this.errorTitle = 'FABRICANTE CRIADO';
        this.errorBody = 'Fabricante criado com sucesso!!';
        this.open(this.contentError)
        this.showRedirect = true;
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err)
      }
    )
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = 'https://static.japanparts.com.br/'+thumb;
  	this.selectedManufacturer = name;
  	this.open(content)
  }

}
