import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-newsletter-list',
  templateUrl: './newsletter-list.component.html',
  styleUrls: ['./newsletter-list.component.css']
})
export class NewsletterListComponent implements OnInit {
	allNews:any;
	environment: any;
  baseUrl: string;

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  	this.retrieveAllNews()
  }

  retrieveAllNews() {
  	this.http.get(this.baseUrl+'/newsletters')
    .subscribe(
      res => {
        console.log(res)
        this.allNews = res;
      }, 
      err => {
      	console.log(err)
      })
  }

}
