import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-part-equiv',
  templateUrl: './part-equiv.component.html',
  styleUrls: ['./part-equiv.component.css']
})
export class PartEquivComponent implements OnInit {
	baseUrl:string;
  baseImg:string;
  loadingParts:boolean;
	allPartsResponse:any;
	allParts:any;
	allVehicles:any;
	allVehiclesRes:any;
	partQuery:string
	selectedPart:any = {};
	closeResult:string;
  imageToshow:string;
  query:string;
  newEquiv:any = {};
  allPartsManufs:any = [];

  @ViewChild('content') content: ElementRef;

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.baseUrl = environment.baseUrl;
    this.baseImg = environment.baseImgPrefix;
  }

  ngOnInit() {
  	this.retrieveAllParts('');
  	this.retrieveAllPartManuf();
  }

  retrieveAllParts(query) {
  	this.loadingParts = true;
  	console.log(query)
  	this.http.get(this.baseUrl+'/parts?filter[limit]=10&filter[include][equivalencies]' + (query.length > 0 ? `&filter[where][code][like]=%${query}%` : ''))
    .subscribe(
      res => {
        console.log('parts', res)
        this.allPartsResponse = res;
        this.allParts = this.allPartsResponse;
        this.loadingParts = false;
      },
      err => {
      	console.log(err)
      })
  }

  retrieveAllPartManuf() {
  	this.http.get(this.baseUrl+'/part_manufacturers?filter[order]=name')
    .subscribe(
      res => {
        console.log('partmanufs', res)
        this.allPartsManufs = res;
      },
      err => {
      	console.log(err)
      })
  }

  createEquiv() {
  	if(!this.newEquiv.code) {
  		return this.toastr.error('Precisa colocar o código da equivalência', 'ERRO', {positionClass: 'toast-top-center'});
  	}
  	if(!this.newEquiv.original) {
  		return this.toastr.error('Precisa selecionar se é original', 'ERRO', {positionClass: 'toast-top-center'});
  	}
  	if(!this.newEquiv.manuf) {
  		return this.toastr.error('Precisa selecionar o fabricante', 'ERRO', {positionClass: 'toast-top-center'});
  	}
  	if(!this.selectedPart.id) {
  		return this.toastr.error('Precisa selecionar o filtro', 'ERRO', {positionClass: 'toast-top-center'});
  	}

  	this.http.post(this.baseUrl+'/part_equivalences', {
	    part_id: this.selectedPart.id,
	    part_manufacturer_id: this.newEquiv.manuf.id,
	    original: this.newEquiv.original,
	    equivalence_code: this.newEquiv.code,
	    equivalence_code_formatted: this.newEquiv.code.replace(/[^0-9a-zA-Z]/g, "").trim(),
    })
    .subscribe(
      res => {
        console.log(res)
        this.sendPartToIndex(this.selectedPart.code);
        this.toastr.success('Equivalência criada com sucesso', 'Successo', {positionClass: 'toast-top-center'});
        this.selectedPart.equivalencies.push(res);
        this.newEquiv.code = '';
        this.newEquiv.original = undefined;
        this.newEquiv.manuf = undefined;
      },
      err => {
        console.log(err)
        this.toastr.error('Erro ao associar!', 'ERRO', {positionClass: 'toast-top-center'});
      })
  }

  removeEquivAssociated(equiv) {
    this.http.delete(this.baseUrl+'/part_equivalences/'+equiv.id)
    .subscribe(
      res => {
        this.selectedPart.equivalencies.splice(this.selectedPart.equivalencies.findIndex(id => id === equiv.id),1)
        console.log(res)
        this.sendPartToIndex(this.selectedPart.code);
        this.toastr.warning('Equivalência removida com sucesso', 'Successo', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Erro ao remover Equivalência!', 'ERRO', {positionClass: 'toast-top-center'});
      })
  }

  sendPartToIndex(partCode) {
    console.log('mandou')
    this.http.get(this.baseUrl+'/parts/sendtoindex?partcode='+partCode)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Peça na fila para indexar!', 'Successo!', {positionClass: 'toast-top-center'});
        this.retrieveAllParts('');
      },
      err => {
        console.log(err)
        this.toastr.error('Falha ao enviar peça para indexação, salve novamente!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

}
