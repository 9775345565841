import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.css']
})
export class PartListComponent implements OnInit {
	allParts: any;
  allPartsResponse:any;
	environment: any;
  baseUrl: string;
  baseImg:string
  loadingParts: boolean;
  imageToshow: string;
  selectedPart: string;
  closeResult:any;
  query:string;
  partFeature:string;
  partStatus:string;

  @ViewChild('contentError') contentError: ElementRef;

  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
  	this.baseUrl = environment.baseUrl;
    this.baseImg = environment.baseImgPrefix;
  }

  ngOnInit() {
  	this.query = '';
    this.partFeature = '';
    this.partStatus = '';
    this.retrieveAllParts();
  }

  redirectToEdit(part_id) {
    this.router.navigate(['/editar-produto/'+part_id]);
  }

  open(content) { 
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = this.baseImg+thumb;
  	this.selectedPart = name;
  	this.open(content)
  }

  retrieveAllParts() {
  	this.loadingParts = true;
  	this.http.get(this.baseUrl+'/parts/partsearch?query='+this.query)
    .subscribe(
      res => {
        console.log(res)
        this.allPartsResponse = res;
        this.allParts = this.allPartsResponse.data;
        this.loadingParts = false;
      }, 
      err => {
      	console.log(err)
      })
  }

  updateIndex() {
    this.http.get(this.baseUrl+'/parts/startindexing')
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Infos atualizadas com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      }, 
      err => {
        console.log(err)
        this.toastr.error('Não foi!', 'ERRO!', {positionClass: 'toast-top-center'});
      }
    )
  }

}
