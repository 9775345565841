import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
	environment: any;
  baseUrl: string;
  selectedFilter:any;
	users:any;
	createNew:boolean;
	newUser:any;
  loadingUsers: boolean;
  downloadLink:string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  	this.createNew = false;
  	this.retrieveAllUsers();
    this.loadingUsers = true;
    // this.users = [
  	// {id:0, first_name: 'Carlos', last_name: 'Matos', email: 'karlos.c.m@gmail.com'}]
  }

  showCreateNewUser() {
  	this.createNew = !this.createNew;
  	this.newUser = {};
  }

  retrieveAllUsers() {
    this.http.get(this.baseUrl+'/users')
    .subscribe(
      res => {
        console.log(res)
        this.users = res;
        this.loadingUsers = false;
      })
  }

  createNewUser() {
  	this.http.post(this.baseUrl+'/users', {
      first_name: this.newUser.first_name,
      last_name: this.newUser.last_name,
      email: this.newUser.email,
      password: this.newUser.password
    }).subscribe(
      res => {
        console.log(res)
      },
      err => {
        console.log('err')
      }
    )
  }

}
