import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
	credentials: any = {};
	cookieValue = 'UNKNOWN';
  message: string;
  responseObject:any;
  environment:any;
  imgUrl:string;
  baseUrl:string;

	constructor(private cookieService: CookieService, private router: Router, private http: HttpClient) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
  };

	ngOnInit() {
    console.log('OI');
    this.credentials.email = "admin@japanparts.com.br";
    // admin*japan
  }

	LogIn():void {
    if(!this.credentials.email || !this.credentials.password) {
    	console.log('num tem')
      return
    }
    this.http.post(this.baseUrl+'/users/login', {
			email: this.credentials.email,
			password: this.credentials.password
		}).subscribe(
      res => {
        console.log(res)
        this.responseObject = res;
        this.cookieService.set( 'authorization', this.responseObject.id);
        this.cookieService.set('user_id', this.responseObject.userId);
        this.router.navigate(['/listar-produtos']);
      },
      err => {
        console.log('err')
      }
    )
	}
}
