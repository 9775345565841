import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tradefair-list',
  templateUrl: './tradefair-list.component.html',
  styleUrls: ['./tradefair-list.component.css']
})
export class TradefairListComponent implements OnInit {
	allFairs: any;
	environment: any;
  baseUrl: string;
  imgUrl:string;
  imageUrlPrefix:string;
  loadingFairs: boolean;
  imageToshow: string;
  selectedTrade: string;
  closeResult:any;
  selectedFair:any;
  newImages:any;
  newFiles:any;
  folder:string;
  randomString:string;
  errorMsg:string;
  errorTitle:string;
  errorBody:string;

  acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('multipleFileInput') multipleFileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
  	this.imageUrlPrefix = environment.baseImgPrefix;
    this.folder = 'imgsfeiras';
  }

  ngOnInit() {
  	this.retrieveAllTrades()
    // this.toastr.success('You are awesome!', 'Success!', {positionClass: 'toast-top-center'});
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = this.imageUrlPrefix + thumb;
  	this.selectedTrade = name;
  	this.open(content)
  }

  retrieveAllTrades() {
  	this.loadingFairs = true;
  	this.http.get(this.baseUrl+'/trade_fairs?filter={"include":["tradeFairImages"]}')
    .subscribe(
      res => {
        console.log(res)
        this.allFairs = res;
        this.loadingFairs = false;
        // this.selectedFair = [];
      },
      err => {
      	console.log(err)
      })
  }

  removeImage(imgId) {
    this.http.delete(this.baseUrl+'/trade_fair_images/'+imgId)
    .subscribe(
      res => {
        console.log(res)
        this.retrieveAllTrades()
        this.toastr.success('Imagem removida!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
      })
  }

  featureImage(trade) {
    this.http.post(this.baseUrl+'/trade_fair_images/featureimg', {
      trade: {
        imgid: trade.id,
        tradeid: trade.trade_fair_id
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.retrieveAllTrades()
        this.toastr.success('Imagem marcada como destaque!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
      })
  }

  changeStatus(trade) {
    this.http.post(this.baseUrl+'/trade_fairs/newstatus', {
      trade: {
        status: trade.status,
        id: trade.id
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.retrieveAllTrades()
        this.toastr.success('Status Alterado com Sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
      })
  }

  regenerateRandomString() {
    this.randomString = Math.random().toString(36).substring(2, 15)
  }

  uploadFairImages(event: Event) {
    this.errorMsg = '';
    if(!this.newImages) {
      this.toastr.error('Precisa selecionar pelo menos uma imagem!', 'ERRO!', {positionClass: 'toast-top-center'});
      return this.errorMsg = 'Não tem nenhum arquivo';
    }
    this.toastr.warning('Subindo imagens e atualizando DB!', 'ATENÇÃO!', {positionClass: 'toast-top-center'});
    event.preventDefault();
    // get only the base64 file
    if (this.newImages.length > 0) {
      for (var i = 0; i < this.newImages.length; i++) {
        this.regenerateRandomString();
        const base64File = this.newImages[i].split(',')[1];
        console.log(base64File)
        // TODO: send to server
        this.http.post(this.imgUrl, {
          folder: this.folder,
          image: base64File
        }).subscribe((res: any) => {
          this.insertImageInTable(this.selectedFair.id, res.fileName);
        }, err => console.error(err));
      }
      // this.retrieveAllTrades()
    }
  }

  insertImageInTable(trade_fair_id, thumb) {
    this.http.post(this.baseUrl+'/trade_fair_images', {
      trade_fair_id: trade_fair_id,
      thumb: '/' + thumb,
      img: '/' + thumb,
      feature: 0
    }).subscribe(
      res => {
        console.log(res)
        this.toastr.success('Imagem Atualizada com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      }, err => {
        console.log(err)
      })
  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 5000000;
  }

  previewFileImages() {
    this.newImages = [];
    this.newFiles = this.multipleFileInput.nativeElement.files;
    console.log('files',this.newFiles);
    for (var i = 0; i < this.newFiles.length; i++) {
      if (this.newFiles[i] && this.validateFile(this.newFiles[i])) {
        this.errorMsg = '';
        const reader = new FileReader();
        reader.readAsDataURL(this.newFiles[i]);
        reader.onload = () => {
          this.fileDataUri = reader.result;
          this.newImages.push(this.fileDataUri)
        }
      } else {
        this.toastr.error('Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb!', 'ERRO!', {positionClass: 'toast-top-center'});
        this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
        this.errorTitle = 'ARQUIVO INVÁLIDO';
        this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      }
    }
  }

}
