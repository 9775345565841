import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-video-create',
  templateUrl: './video-create.component.html',
  styleUrls: ['./video-create.component.css']
})
export class VideoCreateComponent implements OnInit {
	newVideo:any = {};
	imgUrl:string;
  baseUrl:string;

  constructor(private http: HttpClient, public toastr: ToastrService) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.newVideo.embed_link = 'https://www.youtube.com/embed/'
  }

  createVideo() {
  	console.log(this.newVideo)
  	this.http.post(this.baseUrl+'/videos', {
      title: this.newVideo.title,
      description: this.newVideo.description,
      embed_link: this.newVideo.embed_link
    }).subscribe(
      res => {
        console.log('respost', res)
        this.toastr.success('Video Criado com sucesso!', 'Success!', {positionClass: 'toast-top-center'});
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err)
      }
    )
  }

}
