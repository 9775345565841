import { Injectable, Injector } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
	baseUrl = environment.baseUrl;
    authHeader: string;

	constructor(private cookieService: CookieService, private router: Router, private location: Location) {}
	
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(this.location.path() != '/login' && !this.cookieService.get('authorization')) {
            console.log('tem nao');
            this.router.navigate(['/login']);
        } else {
            console.log('tem aqui nada');
            const authHeader = this.cookieService.get('authorization');
            // const url = this.baseUrl;
            // req = req.clone({
            //   url: url + req.url
            // });
            // req = req.clone({ headers: req.headers.set("Access-Control-Request-Method: POST, GET, PUT, POST, DELETE, OPTIONS")});
            // req = req.clone({ headers: req.headers.set("Access-Control-Allow-Origin", "*")});
            req = req.clone({ headers: req.headers.set("Content-Type", "application/x-www-form-urlencoded")});
            req = req.clone({ headers: req.headers.set("authorization", authHeader)});
            req = req.clone({ headers: req.headers.set("Content-Type", "application/json")});
            console.log('passou');
            return next.handle(req);
        }        
        // const url = this.baseUrl;
        // req = req.clone({
        //   url: url + req.url
        // });
        // req = req.clone({ headers: req.headers.set("Authorization", "auth")})
        // req = req.clone({ headers: req.headers.set("x-api-header", "auth")})
        // req = req.clone({ headers: req.headers.set("Access-Control-Request-Headers", "Authorization, Content-Type")});
	}
}