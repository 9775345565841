import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
	allContacts:any;
	environment: any;
  baseUrl: string;

  constructor(private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  	this.retrieveAllContacts()
  }


  retrieveAllContacts() {
  	this.http.get(this.baseUrl+'/contacts')
    .subscribe(
      res => {
        console.log(res)
        this.allContacts = res;
      }, 
      err => {
      	console.log(err)
      })
  }


}
