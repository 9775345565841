import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-part-edit',
  templateUrl: './part-edit.component.html',
  styleUrls: ['./part-edit.component.css']
})
export class PartEditComponent implements OnInit {
	part_id:string;
	part:any = {};
	loadingPart:boolean;
	environment: any;
  baseUrl: string;
  imgUrl: string;
  folder: string;
  launchFolder:string;
  file: any;
  launchfile:any;
  newFiles:any;
  errorMsg:string;
	errorTitle:string;
	errorBody:string;
	newImages:any = [];
	partImages:any;
  dimResponse:any;
  partDimensions:any;
	imageUrlPrefix:string;
	randomString:string;
  launch_date:string;
  full_launch_date:any;
  launchResponse:any;
  launch_year:number;
  launch_month:number;
  string_launch_month:string;
  launch_day:number;
  categories: any;
  subcategories: any;

  acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('multipleFileInput') multipleFileInput: ElementRef;
  @ViewChild('fileInputLaunch') fileInputLaunch: ElementRef;
  fileDataUri = '';
  fileDataUriLaunch= '';

  constructor(private route: ActivatedRoute, private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
  	this.baseUrl = environment.baseUrl;
  	this.imgUrl = environment.baseImgUrl;
  	this.folder = 'imgsprodutos';
    this.launchFolder = 'imgslaunch';
  	this.imageUrlPrefix = environment.baseImgPrefix;
  }

  ngOnInit() {
  	this.route.params.subscribe(params => {
  		this.partDimensions = {weight: 0,length: 0,width: 0,height: 0,internal_diam: 0,external_diam: 0,screw_in: 0,screw_out: 0,beak_in: 0,beak_out: 0}
      this.part_id = params.part_id;
  		this.retrievePartInfos(this.part_id)
  		this.retrievePartImages(this.part_id)
      this.retrievePartDimensions(this.part_id)
      this.retrievePartLaunch(this.part_id)
      this.retrieveCategoriesAndSub()
  	})
  }

  regenerateRandomString() {
    this.randomString = Math.random().toString(36).substring(2, 15);
    return this.randomString;
  }

  retrievePartInfos(part_id) {
  	this.loadingPart = true;
  	this.http.get(this.baseUrl+'/parts/findOne'+'?filter[where][id]='+part_id)
    .subscribe(
      res => {
        console.log('infos', res)
        this.part = res;
        this.fileDataUri = this.imageUrlPrefix + this.part.thumb;
        this.loadingPart = false;
      },
      err => {
      	console.log(err)
      })
  }

  retrieveCategoriesAndSub() {
  	this.loadingPart = true;
  	this.http.get(this.baseUrl+'/part_categories')
    .subscribe(
      res => {
        console.log('categories', res)
        this.categories = res;
      },
      err => {
      	console.log(err)
      })

    this.http.get(this.baseUrl+'/part_subcategories')
      .subscribe(
        res => {
          console.log('subcategories', res)
          this.subcategories = res;
        },
        err => {
          console.log(err)
        })
  }

  retrievePartImages(part_id) {
  	this.loadingPart = true;
  	this.http.get(this.baseUrl+'/part_images?filter[where][part_id]='+part_id)
    .subscribe(
      res => {
        console.log(res)
        this.partImages = res;
        this.loadingPart = false;
      },
      err => {
      	console.log(err)
      })
  }

  retrievePartDimensions(part_id) {
    this.loadingPart = true;
    this.http.get(this.baseUrl+'/part_dimensions'+'?filter[where][part_id]='+part_id)
    .subscribe(
      res => {
        console.log(res)
        this.dimResponse = res;
        if(this.dimResponse.length > 0) {
          this.partDimensions = this.dimResponse[0];
        }

        this.loadingPart = false;
      },
      err => {
        console.log(err)
      })
  }


  deletePart() {
    let confirmation = confirm("Tem certeza que quer deletar?");

    if (!confirmation) return;

    this.http.delete(this.baseUrl+'/parts/'+this.part_id)
      .subscribe(
        res => {
          console.log(res)
          this.toastr.success('Produto deletado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        },
        err => {
          console.log(err)
        })

  }

  retrievePartLaunch(part_id) {
    this.loadingPart = true;
    this.http.get(this.baseUrl+'/part_launches'+'?filter[where][part_id]='+part_id)
    .subscribe(
      res => {
        console.log(res)
        this.launchResponse = res;
        if(this.launchResponse.length > 0) {
          this.fileDataUriLaunch = this.imageUrlPrefix + this.launchResponse[0].launch_image;
          this.full_launch_date = new Date(this.launchResponse[0].launch_date);
          console.log(this.full_launch_date.getFullYear(),this.full_launch_date.getMonth(), this.full_launch_date.getDate())
          this.launch_year = this.full_launch_date.getFullYear();
          this.launch_month = this.full_launch_date.getMonth()+1;
            if(this.launch_month <= 9) {
              this.string_launch_month = '0' + this.launch_month.toString()
            } else {
              this.string_launch_month = this.launch_month.toString()
            }
          this.launch_day = this.full_launch_date.getDate() +1;
          this.launch_date =  this.launch_year.toString() +'-'+ this.string_launch_month +'-'+ this.launch_day.toString();
        }

        this.loadingPart = false;
      },
      err => {
        console.log(err)
      })
  }

  updatePartInfos() {
    this.loadingPart = true;
    const subcategory = this.subcategories.find(subcat => subcat.id == this.part.part_subcategory_id);

  	this.http.put(this.baseUrl+'/parts/'+this.part.id, {
      addtoindex: 1,
      bar_code: this.part.bar_code,
      code: this.part.code,
      code_formatted: this.part.code.replace(/[^0-9a-zA-Z]/g, "").trim(),
      description: this.part.description,
      feature: this.part.feature,
      thumb: this.part.img,
      img: this.part.img,
      part_category_id: this.part.part_category_id,
      part_subcategory_id: this.part.part_subcategory_id,
      part_subcategory_name: subcategory.name,
      status: this.part.status
  	})
    .subscribe(
      res => {
        console.log(res)
        this.retrievePartInfos(this.part.id)
        this.toastr.success('Infos atualizadas com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        this.loadingPart = false;
        this.sendPartToIndex(this.part.code);
      },
      err => {
      	console.log(err)
      })
  }

  uploadFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUri) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      console.log(base64File	)
      // TODO: send to server
      this.http.post(this.imgUrl, {
        folder: this.folder,
        image: base64File
      }).subscribe((res: any) => {
        this.toastr.success('Arquivo atualizado com sucesso!', 'Sucesso!', { positionClass: 'toast-top-center' });
        this.updatePartImg(this.part.id, res.fileName);
      }, err => console.error(err));
    }
  }

  updatePartImg(part_id, img_url) {
    this.http.post(this.baseUrl+'/parts/updateimgurl', {
      imginfos:{
        id: part_id,
        new_url: '/' + img_url
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Imagem alterada com sucesso no DB!', 'Successo!', {positionClass: 'toast-top-center'});
        this.loadingPart = false;
        this.sendPartToIndex(this.part.code);
      },
      err => {
        console.log(err)
      })
  }

  uploadLaunchFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUriLaunch || !this.launch_date) {
      this.toastr.error('Você precisa de uma imagem e uma dara para atualizar!', 'ERRO!', {positionClass: 'toast-top-center'});
      // this.updatePartLaunch();
      return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUriLaunch.length > 0) {
      const base64File = this.fileDataUriLaunch.split(',')[1];
      console.log(base64File)
      // TODO: send to server
      this.http.post(this.imgUrl, {
        folder: this.launchFolder,
        image: base64File
      }).subscribe((res: any) => {
        this.toastr.success('Arquivo atualizado com sucesso!', 'Sucesso!', { positionClass: 'toast-top-center' });
        this.updatePartLaunch(res.fileName);
      }, err => console.error(err));
    }
  }

  uploadPartImages(event: Event) {
    this.errorMsg = '';
    if(!this.newImages) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.newImages.length > 0) {
	    for (var i = 0; i < this.newImages.length; i++) {
	      let randomString = this.regenerateRandomString();
	      const base64File = this.newImages[i].split(',')[1];
	      console.log(base64File)
	      // TODO: send to server
	      this.http.post(this.imgUrl, {
	        folder: this.folder,
          image: base64File
        }).subscribe((res: any) => {
          this.insertImageInTable(this.part.id, res.fileName);
        }, err => console.error(err));
	    }
    }
  }

  insertImageInTable(part_id, thumb) {
  	this.http.post(this.baseUrl+'/part_images', {
  		part_id: part_id,
  		thumb: '/' + thumb,
      status: 1
  	}).subscribe(
  		res => {
  			console.log(res)
  			this.retrievePartImages(this.part.id)
        this.toastr.success('Imagem atualizada com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
  		}, err => {
  			console.log(err)
  		})
  }

  updatePartDimensions() {
    console.log(this.partDimensions)
    this.loadingPart = true;
    this.http.post(this.baseUrl+'/part_dimensions/partdimensions', {
      dim:{
        part_id: this.part.id,
        weight: this.partDimensions.weight,
        length: this.partDimensions.length,
        width: this.partDimensions.width,
        height: this.partDimensions.height,
        internal_diam: this.partDimensions.internal_diam,
        external_diam: this.partDimensions.external_diam,
        screw_in: this.partDimensions.screw_in,
        screw_out: this.partDimensions.screw_out,
        beak_in: this.partDimensions.beak_in,
        beak_out: this.partDimensions.beak_out
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Dimensões Alteradas com Sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        this.loadingPart = false;
        this.sendPartToIndex(this.part.code);
      },
      err => {
        console.log(err)
      })
  }

  updatePartLaunch(fileName) {
    console.log(this.partDimensions)
    this.loadingPart = true;
    this.http.post(this.baseUrl+'/part_launches/partlaunch', {
      data:{
        part_id: this.part.id,
        launch_date: this.launch_date,
        launch_image: '/' + fileName,
        launch_thumb: '/' + fileName,
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Lançamento atualizado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
        this.loadingPart = false;
        this.sendPartToIndex(this.part.code);
      },
      err => {
        console.log(err)
      })
  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 5000000;
  }

  previewAndUploadFile(event) {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
        this.uploadFile(event);
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      // this.open(this.contentError)
    }
  }

  previewFileImages() {
    this.newImages = [];
    this.newFiles = this.multipleFileInput.nativeElement.files;
    console.log('files',this.newFiles);
	  for (var i = 0; i < this.newFiles.length; i++) {
	    if (this.newFiles[i] && this.validateFile(this.newFiles[i])) {
	    	this.errorMsg = '';
	      const reader = new FileReader();
	      reader.readAsDataURL(this.newFiles[i]);
	      reader.onload = () => {
	        this.fileDataUri = reader.result;
	        this.newImages.push(this.fileDataUri)
	      }
	    } else {
	      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
	      this.errorTitle = 'ARQUIVO INVÁLIDO';
	      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
	    }
	  }
  }

  previewLaunch() {
    const file = this.fileInputLaunch.nativeElement.files[0];
    this.launchfile = file;
    if (this.launchfile && this.validateFile(this.launchfile)) {
      this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInputLaunch.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUriLaunch = reader.result;
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      // this.open(this.contentError)
    }
  }

  removeImage(imgId) {
  	this.http.delete(this.baseUrl+'/part_images/'+imgId)
  	.subscribe(res => {
  		console.log(res)
  		this.retrievePartImages(this.part.id)
      this.toastr.success('Imagem removida com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
  	}, err => console.log(err))
  }

  changeFeature(event) {
    console.log(event)
    this.http.post(this.baseUrl+'/parts/parteditaction', {
      data:{
        action: 'feature',
        newValue: event,
        filterId: this.part_id
      }
    })
    .subscribe(res => {
      console.log(res)
      this.sendPartToIndex(this.part.code);
      this.toastr.success('Destaque alterado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
    }, err => console.log(err))
  }

  changeStatus(event) {
    console.log(event)
    this.http.post(this.baseUrl+'/parts/parteditaction', {
      data:{
        action: 'status',
        newValue: event,
        filterId: this.part_id
      }
    })
    .subscribe(res => {
      console.log(res)
      this.toastr.success('Status alterado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      this.sendPartToIndex(this.part.code)
    }, err => console.log(err))
  }

  sendPartToIndex(partCode) {
    console.log('mandou')
    this.http.get(this.baseUrl+'/parts/sendtoindex?partcode='+partCode)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Peça na fila para indexar!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Falha ao enviar peça para indexação, salve novamente!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

}
