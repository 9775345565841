import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { dateIsNotZeroValidator, dateLengthValidator, isAfterValidator } from '../validators';
import { isoDateToMmYyyy, mmYyyyToDate } from '../../../utils/date';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.css']
})
export class VehicleEditComponent implements OnInit {
	baseUrl:string;
	imgUrl:string;
	loadingPart:boolean;
	imageUrlPrefix:string;
	vehicle_id:string;
	vehicle:any = {};
	allLines:any = [];
	allManufacturers:any = [];
	vehicleName:string;
	file:any;
	errorMsg:string;
	errorTitle:string;
	errorBody:string;
	folder:string;
  form: FormGroup;
	// vehicleline:any = {};

	acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private modalService: NgbModal, public toastr: ToastrService, private fb: FormBuilder) {
  	this.baseUrl = environment.baseUrl;
  	this.imgUrl = environment.baseImgUrl;
  	this.imageUrlPrefix = environment.baseImgPrefix;
  	this.folder = 'imgsveiculos';
  }

  ngOnInit() {
  	this.route.params.subscribe(params => {
      this.vehicle_id = params.vehicle_id;
  		this.retrieveVehicleInfos(this.vehicle_id)
  	})

    this.initForm();
  }

  initForm() {
    const formControls = {};
    const props = ['model', 'engine', 'version', 'line', 'manufacturer', 'yearFrom', 'yearTo'];
    props.forEach(prop => {
      formControls[prop] = [''];

      if (prop === 'yearFrom' || prop === 'yearTo') {
        formControls[prop].push([dateLengthValidator, dateIsNotZeroValidator]);
      }
    });
    this.form = this.fb.group(formControls, { validator: isAfterValidator });
  }

  retrieveFormInfos(controls: Object) {
    const validatedControl = {};

    for (const [key, value] of Object.entries(controls)) {
      if (value !== null) {
        if (key === 'yearFrom' || key === 'yearTo') {
          validatedControl[key] = isoDateToMmYyyy(value);
        } else {
          validatedControl[key] = value;
        }
      }
    }

    this.form.patchValue({ ...validatedControl })
  }

  retrieveVehicleInfos(vehicle_id) {
  	this.loadingPart = true;
  	this.http.get(this.baseUrl+'/vehicles/findOne'+'?filter[include][vehicleManufacturers]&filter[include][vehicleLines]&filter[where][id]='+vehicle_id)
    .subscribe(
      res => {
        console.log(res)
        this.vehicle = res;
        this.vehicleName = this.vehicle.model+this.vehicle.engine+this.vehicle.version;
        this.fileDataUri = this.imageUrlPrefix + this.vehicle.thumb;
        this.loadingPart = false;
        this.retrieveAllLines();
        this.retrieveManufacturers();

        const controls = {
          model: this.vehicle.model,
          engine: this.vehicle.engine,
          version: this.vehicle.version,
          yearFrom: this.vehicle.year_from,
          yearTo: this.vehicle.year_to
        }

        this.retrieveFormInfos(controls);
      },
      err => {
      	console.log(err)
        this.toastr.error('Erro ao carregar infos do veiculo!', 'ERRO!', {positionClass: 'toast-top-center'});
        this.router.navigate(['/listar-veiculos']);
      })
  }

  retrieveAllLines() {
  	this.http.get(this.baseUrl+'/vehicle_lines')
    .subscribe(
      res => {
        console.log('lines', res)
        this.allLines = res;
        for(var i = 0; i < this.allLines.length; i++) {
        	if(this.allLines[i].id == this.vehicle.vehicle_line_id) {
        		this.vehicle.vehicleline = this.allLines[i];
            this.form.patchValue({ line: this.vehicle.vehicleline });
        	}
        }
      },
      err => {
      	console.log(err)
        this.toastr.error('Erro ao carregar linhas!', 'ERRO!', {positionClass: 'toast-top-center'});
        this.router.navigate(['/listar-veiculos']);
      })
  }

  retrieveManufacturers() {
  	this.http.get(this.baseUrl+'/vehicle_manufacturers')
    .subscribe(
      res => {
        console.log('manufs', res)
        this.allManufacturers = res;
        for(var i = 0; i < this.allManufacturers.length; i++) {
        	if(this.allManufacturers[i].id == this.vehicle.vehicle_manufacturer_id) {
        		this.vehicle.vehicleManufacturers = this.allManufacturers[i];
        		this.form.patchValue({ manufacturer: this.vehicle.vehicleManufacturers });
        	}
        }
      },
      err => {
      	console.log(err)
        this.toastr.error('Erro ao carregar fabricantes!', 'ERRO!', {positionClass: 'toast-top-center'});
        this.router.navigate(['/listar-veiculos']);
      })
  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 5000000;
  }

  previewAndUploadFile(event) {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
        this.uploadFile(event);
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      // this.open(this.contentError)
    }
  }

  uploadFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUri) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      console.log(base64File	)
      // TODO: send to server
      const fileName = `${this.vehicleName}${Math.round(Math.random()*1000)}`;
      this.http.post(this.imgUrl, {
        folder: this.folder,
        image: base64File
      }).subscribe((res: any) => {
        this.toastr.success('Arquivo atualizado com sucesso!', 'Sucesso!', { positionClass: 'toast-top-center' });
        this.updateVehicleImg(this.vehicle.id, res.fileName);
      }, err => console.error(err));
    }
  }

  updateVehicleImg(vehicle_id, img_url) {
    this.http.post(this.baseUrl+'/vehicles/updateimgurl', {
      imginfos:{
        id: vehicle_id,
        new_url: '/' + img_url
      }
    })
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Imagem alterada com sucesso no DB!', 'Successo!', {positionClass: 'toast-top-center'});
        this.sendVehiToIndex(this.vehicle.id);
      },
      err => {
        console.log(err)
      })
  }

  sendVehiToIndex(vehiid) {
    // console.log('mandou', this.selectedPart.code)
    this.http.get(this.baseUrl+'/vehicles/sendtoindex?vehiid='+vehiid)
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Veículo na fila para indexar!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Falha ao enviar peça para indexação, salve novamente!', 'ERRO!', {positionClass: 'toast-top-center'});
      })
  }

  updateVehiclesInfos() {
    console.log(this.vehicle, this.vehicleName, this.form)

    if (this.form.valid) {
      this.http.put(this.baseUrl+'/vehicles/updateVehicle', {
        vehicle:{
          id: this.vehicle.id,
          model: this.form.value.model,
          engine: this.form.value.engine,
          vehicle_line_id: this.form.value.line.id,
          vehicle_line_detail: this.form.value.line.name,
          vehicle_manufacturer_id: this.form.value.manufacturer.id,
          year_from: this.form.value.yearFrom ? mmYyyyToDate(this.form.value.yearFrom) : null,
          year_to: this.form.value.yearTo ? mmYyyyToDate(this.form.value.yearTo) : null,
          version: this.form.value.version,
          addtoindex: 1
        }
      })
      .subscribe(
        res => {
          console.log(res)
          this.toastr.success('Veiculo alterado com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
          this.router.navigate(['/listar-veiculos']);
        },
        err => {
          console.log(err)
        })
    } else {
      this.toastr.error('Preencha corretamente os campos inválidos!', 'ERRO!', {positionClass: 'toast-top-center'});
    }
  }

}
