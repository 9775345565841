import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.css']
})
export class CategoryCreateComponent implements OnInit {
	newCategory: any = {};
  environment: any;
  imgUrl: string;
  baseUrl: string;
  folder: string;
  file: any;
  showRedirect:boolean;
  errorMsg: string;
  errorTitle:string;
  errorBody:string;
  closeResult:any;

	acceptedMimeTypes = [
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('contentError') contentError: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal, public toastr: ToastrService) {
    this.imgUrl = environment.baseImgUrl;
    this.baseUrl = environment.baseUrl;
    this.showRedirect = false;
    this.folder = 'imgscategoria';
  }

  ngOnInit() {

  }

  validateFile(file) {
    return this.acceptedMimeTypes.includes(file.type) && file.size < 500000;
  }

  previewFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.file = file;
    if (file && this.validateFile(file)) {
    	this.errorMsg = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
      }
    } else {
      this.errorMsg = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.errorTitle = 'ARQUIVO INVÁLIDO';
      this.errorBody = 'Arquivo deve ser do tipo jpg, png ou gif e não pode passar de 500kb';
      this.open(this.contentError)
    }
  }

  checkIfCategoryExists(event: Event) {
    if(!this.fileDataUri) {
      this.toastr.error('Obrigatótio atribuir uma imagem a categoria!', 'ERRO!', {positionClass: 'toast-top-center'});
      return this.errorMsg = 'Não tem nenhum arquivo';
    }

    this.errorTitle = 'CRIANDO CATEGORIA';
    this.errorBody = 'Estamos subindo a imagem e as informações da nova categoria!';
    this.open(this.contentError)

    this.http.get(this.baseUrl+'/part_categories/findOne'+'?filter[where][name]='+this.newCategory.name)
    .subscribe(
      res => {
        this.errorTitle = 'CATEGORIA DUPLICADA';
        this.errorBody = 'Já existe uma categoria com esse nome e o nome das categorias devem ser únicos!';
        this.open(this.contentError)
      },
      err => {
        console.log(err)
        this.uploadFile(event)
      })
  }

  uploadFile(event: Event) {
    this.errorMsg = '';
    if(!this.fileDataUri) {
    	return this.errorMsg = 'Não tem nenhum arquivo';
    }
    event.preventDefault();
    // get only the base64 file
    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      // TODO: send to server
      this.http.post(this.imgUrl, {
        folder: this.folder,
        image: base64File
      }).subscribe((res: any) => {
        this.createPartCategory(res.fileName);
      }, err => console.error(err));
    }
  }

  createPartCategory(fileName: string) {
    console.log(this.newCategory)
    this.http.post(this.baseUrl+'/part_categories', {
      name: this.newCategory.name,
      description: this.newCategory.description,
      thumb: '/' + fileName,
      class: this.newCategory.class,
      status: 1
    }).subscribe(
      res => {
        console.log('respost', res)
        this.errorTitle = 'CATEGORIA CRIADA';
        this.errorBody = 'Categoria criada com sucesso!!';
        this.open(this.contentError)
        this.showRedirect = true;
        // setInterval(a => {
        //   this.router.navigate(['/listar-categorias']);
        // }, 2000)
      },
      err => {
        console.log('errou', err)
      }
    )
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  redirectToListing() {
    this.router.navigate(['/listar-categorias']);
  }

}
