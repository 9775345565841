import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { isoDateToMmYyyy } from '../../../utils/date';

interface VehicleResponse {
  data: VehicleData[];
}

interface VehicleData {
  engine: string | null;
  id: number;
  line: string | null;
  lineimg: string | null;
  manufacturer: string | null;
  manufactuterimg: string | null;
  model: string | null;
  thumb: string | null;
  vehicle_line_id: number | null;
  vehicle_manufacturer_id: number | null;
  version: string | null;
  year_from: string | null;
  year_to: string | null;
}

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css']
})
export class VehicleListComponent implements OnInit {
	allVehicles: VehicleData[] = [];
	environment: any;
  baseUrl: string;
  baseImg:string;
  loadingVehicles: boolean;
  imageToshow: string;
  selectedCategory: string;
  closeResult:any;
  query:string;

	@ViewChild('contentError') contentError: ElementRef;

  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal, public toastr: ToastrService) {
  	this.baseUrl = environment.baseUrl;
    this.baseImg = environment.baseImgPrefix;
  }

  ngOnInit() {
  	this.retrieveAllVehicles()
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  selectImage(thumb, name, content) {
  	this.imageToshow = this.baseImg+thumb;
  	this.selectedCategory = name;
  	this.open(content)
  }

  retrieveAllVehicles() {
  	this.loadingVehicles = true;
  	this.http.get(this.baseUrl+'/vehicles/vehiclesearch?query='+this.query)
    .subscribe(
      (res: VehicleResponse) => {
        console.log(res)
        this.allVehicles = res.data;
        // this.allVehicles = this.allVehiclesRes.data;
        this.formatAndSetVehicleData(res.data)
        this.loadingVehicles = false;
      },
      err => {
      	console.log(err)
      })

  }

  formatAndSetVehicleData(data: VehicleData[]) {
    this.allVehicles = data.map(vehicle => {
      vehicle.year_from = vehicle.year_from ? isoDateToMmYyyy(vehicle.year_from) : '<--';
      vehicle.year_to = vehicle.year_to ? isoDateToMmYyyy(vehicle.year_to) : '-->';

      return vehicle;
    })
  }

  filterVehicleByVersion(versionQuery: string) {
    if (versionQuery != null || versionQuery == '') {
      this.allVehicles = this.allVehicles.filter(x => {
        if (x.version)
          return x.version.toLowerCase().includes(versionQuery.toLowerCase());
      });
    }
  }

  updateVehiIndex() {
    this.http.get(this.baseUrl+'/vehicles/startindexing')
    .subscribe(
      res => {
        console.log(res)
        this.toastr.success('Veículos indexados com sucesso!', 'Successo!', {positionClass: 'toast-top-center'});
      },
      err => {
        console.log(err)
        this.toastr.error('Não foi!', 'ERRO!', {positionClass: 'toast-top-center'});
      }
    )
  }

  delete(id) {
    const del = confirm(`Deseja mesmo deletar este veículo (id: ${id})? Essa ação não poderá ser desfeita.`)
    if (del)
    this.http.delete(this.baseUrl + '/vehicles/' + id)
      .subscribe(
        res => {
          console.log(res)
          this.toastr.warning('Veículo removido com sucesso', 'Successo', { positionClass: 'toast-top-center' });
        },
        err => {
          console.log(err)
          this.toastr.error('Erro ao remover veículo!', 'ERRO', { positionClass: 'toast-top-center' });
        })
  }

  redirectToEdit(id) {
    this.router.navigate(['/editar-veiculo/'+id]);
  }

}
