import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CustomHttpInterceptor } from './http-interceptor'

import { AppComponent } from './app.component';

import { ButtonsComponent } from './component/buttons/buttons.component';
import { NgbdModalBasic } from './component/modal/modal.component';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { CategoryCreateComponent } from './category/category-create/category-create.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { SubCategoryCreateComponent } from './category/sub-category-create/sub-category-create.component';
import { SubCategoryListComponent } from './category/sub-category-list/sub-category-list.component';
import { VehicleCreateComponent } from './vehicle/vehicle-create/vehicle-create.component';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { VehicleManufacturersComponent } from './vehicle/vehicle-manufacturers/vehicle-manufacturers.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { BannerCreateComponent } from './banner/banner-create/banner-create.component';
import { PartListComponent } from './part/part-list/part-list.component';
import { PartCreateComponent } from './part/part-create/part-create.component';
import { PartEditComponent } from './part/part-edit/part-edit.component';
import { TradefairListComponent } from './tradefair/tradefair-list/tradefair-list.component';
import { TradefairCreateComponent } from './tradefair/tradefair-create/tradefair-create.component';
import { VideoCreateComponent } from './video/video-create/video-create.component';
import { VideoListComponent } from './video/video-list/video-list.component';
import { NewsletterListComponent } from './newsletter/newsletter-list/newsletter-list.component';
import { PartVehicleComponent } from './part/part-vehicle/part-vehicle.component';
import { PartEquivComponent } from './part/part-equiv/part-equiv.component';
import { OrderPipe } from './part/part-equiv/part-equiv-order-pipe';
import { ContactComponent } from './contact/contact/contact.component';
import { VehicleEditComponent } from './vehicle/vehicle-edit/vehicle-edit.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

const routes: Routes = [
    // { path: '', component: HomeComponent },
    { path: 'listar-usuarios', component: UserListComponent },
    { path: 'criar-categoria', component: CategoryCreateComponent },
    { path: 'listar-categorias', component: CategoryListComponent },
    { path: 'criar-sub-categoria', component: SubCategoryCreateComponent },
    { path: 'listar-sub-categorias', component: SubCategoryListComponent },
    { path: 'criar-veiculo', component: VehicleCreateComponent },
    { path: 'listar-veiculos', component: VehicleListComponent },
    { path: 'editar-veiculo/:vehicle_id', component: VehicleEditComponent },
    { path: 'fabricantes-veiculos', component: VehicleManufacturersComponent },
    { path: 'listar-banners', component: BannerListComponent },
    { path: 'criar-banner', component: BannerCreateComponent },
    { path: 'listar-produtos', component: PartListComponent },
    { path: 'criar-produto', component: PartCreateComponent },
    { path: 'editar-produto/:part_id', component: PartEditComponent },
    { path: 'associar-produto-veiculo', component: PartVehicleComponent },
    { path: 'associar-produto-equivalencia', component: PartEquivComponent },
    { path: 'listar-eventos', component: TradefairListComponent },
    { path: 'criar-evento', component: TradefairCreateComponent },
    { path: 'listar-videos', component: VideoListComponent },
    { path: 'criar-video', component: VideoCreateComponent },
    { path: 'listar-newsletters', component: NewsletterListComponent },
    { path: 'listar-contatos', component: ContactComponent },
    { path: 'login', component: LoginComponent },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ButtonsComponent,
    NgbdModalBasic,
    LoginComponent,
    HomeComponent,
    UserListComponent,
    UserCreateComponent,
    CategoryCreateComponent,
    SubCategoryCreateComponent,
    SubCategoryListComponent,
    CategoryListComponent,
    VehicleCreateComponent,
    VehicleListComponent,
    VehicleManufacturersComponent,
    BannerListComponent,
    BannerCreateComponent,
    PartListComponent,
    PartCreateComponent,
    PartEditComponent,
    TradefairListComponent,
    TradefairCreateComponent,
    VideoCreateComponent,
    VideoListComponent,
    NewsletterListComponent,
    PartVehicleComponent,
    PartEquivComponent,
    ContactComponent,
    VehicleEditComponent,
    OrderPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    HttpClientModule,
    HttpModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    PerfectScrollbarModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
      CookieService,
      {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
      {provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
